import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ChangeEvent, Fragment, Suspense, useEffect, useState } from 'react';

import Member from 'views/Member';
import useWebMainLayout from 'components/layouts/hooks/Controller/useWebMainLayout';

import { getClass } from 'util/tailwind';

import WebNavigation from 'components/layouts/WebNavigation';
import WebHomeFooter from './WebHomeFooter';
import WebFooter from 'components/layouts/WebFooter';
import LogisticNavigationAdaptive from './LogisticNavigationAdaptive';
import styled from 'styled-components';
import CookieConsent from 'components/CookieConsent';
import { getCookie } from 'util/cookies';

// import { isNavOpen } from 'store/navigationState';
import useMemoCookies from 'hooks/Common/useMemoCookies';

import { PaymentNoticePop } from './Notice';
import sweetAlert from 'components/Alert/sweetAlert';
import { DateTime } from 'luxon';

const Wrapper = styled.div``;

interface WebMainLayoutProps extends RouteComponentProps {
  children: React.ReactNode;
}

const NO_SHOW_RELEASE_FREE_NOTICE_COOKIE = 'release-notice-payment-free-pop-close';

const NO_SHOW_SERVICE_POP = 'service-notice-pop-20240902';

function WebMainLayout({ children }: WebMainLayoutProps) {
  const [showCookieConsented, setShowCookieConsented] = useState(false);
  const { showLoginVar, topClick, pathName } = useWebMainLayout();
  const memoCookies = useMemoCookies();

  const [showPaymentNotice, setShowPaymentNotice] = useState(false);

  useEffect(() => {
    const hasConsent = getCookie('cookie-consent-agree');

    if (hasConsent) {
      setShowCookieConsented(false);
    } else {
      setShowCookieConsented(true);
    }
  }, [setShowCookieConsented, pathName]);

  useEffect(() => {
    memoCookies.remove('service-notice-pop-20240821', {
      path: '/',
    });
    memoCookies.remove('congestion-apology-popup-hidden', {
      path: '/',
    });
    const notShow = memoCookies.get(NO_SHOW_RELEASE_FREE_NOTICE_COOKIE);
    if (notShow || pathName.includes('plan')) {
      setShowPaymentNotice(false);
    } else {
      setShowPaymentNotice(true);
    }
  }, [memoCookies, setShowPaymentNotice, pathName]);

  useEffect(() => {
    const has = getCookie(NO_SHOW_SERVICE_POP);
    if (has) return;
    const currentDate = DateTime.now().toMillis();

    if (currentDate >= Date.parse('2024-09-24')) {
      memoCookies.remove(NO_SHOW_SERVICE_POP, {
        path: '/',
      });
      return;
    }

    sweetAlert({
      icon: 'info',
      title: '[공지] 라카이브 위클리 서비스 이용 안내',
      width: '640px',
      description: (
        <div className="text-[17px] flex flex-col gap-3 text-black">
          <div>안녕하세요. </div>
          <div>라카이브 위클리를 관심 있게 읽어주시는 모든 분들께 진심으로 감사를 드립니다.</div>
          <div>
            내부 정비로 <b>휴간기간은 [8월 27일]부터 [9월 17일]까지이며,</b>
          </div>
          <div>
            <b>이후 [9월 24일]에 정상 발행</b>될 예정입니다.
          </div>
          <div>많은 양해 부탁드리며, 더 나은 콘텐츠로 찾아뵐 수 있도록 최선을 다하겠습니다.</div>
          <div>감사합니다.</div>
          <div className="flex items-center justify-center gap-5">
            <div
              // onClick={() => {
              //   memoCookies.set(NO_SHOW_SERVICE_POP, true, {
              //     maxAge: 24 * 60 * 60,
              //   });
              // }}

              className="flex justify-center gap-2 mt-5 cursor-pointer"
            >
              <input
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const {
                    currentTarget: { checked },
                  } = e;

                  if (checked) {
                    memoCookies.set(NO_SHOW_SERVICE_POP, true, {
                      maxAge: 24 * 60 * 60 * 7,
                      path: '/',
                    });
                  } else {
                    memoCookies.remove(NO_SHOW_SERVICE_POP, {
                      path: '/',
                    });
                  }
                }}
                id={NO_SHOW_SERVICE_POP + '1'}
                type="checkbox"
                className="w-[20px] aspect-square"
              />
              <label className="cursor-pointer mt-1" htmlFor={NO_SHOW_SERVICE_POP + '1'}>
                7일간 보지않기
              </label>
            </div>
            {/* <div
              onClick={() => {
                memoCookies.set(NO_SHOW_SERVICE_POP, true, {
                  maxAge: 24 * 60 * 60 * 7,
                });
              }}
              className="flex justify-center gap-2 mt-5 cursor-pointer"
            >
              <input id={NO_SHOW_SERVICE_POP + '7'} type="checkbox" />
              <label className="cursor-pointer" htmlFor={NO_SHOW_SERVICE_POP + '7'}>
                일주일동안 보지 않기
              </label>
            </div> */}
          </div>
        </div>
      ),
    });
  }, [memoCookies, pathName]);

  return (
    <Wrapper className={getClass('min-w-[1600px] w-full ')}>
      <main className="min-h-[60vh] relative">
        {showPaymentNotice && (
          <PaymentNoticePop cookieName={NO_SHOW_RELEASE_FREE_NOTICE_COOKIE} close={setShowPaymentNotice} />
        )}
        <WebNavigation />
        <div className="flex">
          {pathName.includes('logistic-tools') && <LogisticNavigationAdaptive />}
          {children}
        </div>
        {showCookieConsented && <CookieConsent setShowCookieConsented={setShowCookieConsented} />}
      </main>
      {showLoginVar && (
        <Suspense fallback={<Fragment></Fragment>}>
          <Member />
        </Suspense>
      )}

      {pathName === '/' ? <WebHomeFooter /> : <WebFooter />}
      <div className="w-[50px] h-[50px] fixed bottom-[100px] right-[30px]">
        <div
          onClick={topClick}
          className="w-full h-full bg-white cursor-pointer rounded-2xl
            bg-[rgba(0,0,0,0.4)]
            opacity-20
            hover:opacity-100
            text-navyColor
            flex
            justify-center
            items-center
          "
        >
          Top
        </div>
      </div>
    </Wrapper>
  );
}

export default withRouter(WebMainLayout);
